.top-bar {
  background: #df1f28;
  width: 100%;
}

.social-box {
  display: block;
}
.social-box ul {
  margin: 0;
  padding: 0;
}

.social-box ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  color: #ffffff;
  font-size: 14px;
}

.social-box ul li a {
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
}
.social-box ul li a:hover {
  color: #3398cb;
}

.nav-link {
  display: block;
  color: #333;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-size: 16px;

  font-family: "Oswald", sans-serif;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 15px;
  padding-left: 15px;
}

.navbar-expand-lg .navbar-nav .nav-link.active,
.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #fff;
  background: #083ba3;
  border-radius: 5px;
}

.tagline {
  font-size: 21px;
  font-family: "BrushScriptOpti-Regular";
  color: #eb1e20;
}
.navbar-nav .nav-link.active {
  color: #fff !important;
  background-color: #083ba3 !important;
  border-radius: 5px;
}
