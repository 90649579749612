.footer {
  padding: 20px 0 !important;
  color: #fff;
  background: #0f1320;
  border-top: 1px solid #d9d9d9;
  font-size: 14px;
}

.footer h4 {
  font-size: 18px;
  font-family: "Oswald", sans-serif;
}

.footer p {
  font-size: 14px;
}

.footer-links {
  list-style: none;
  padding: 0;
  color: #fff;
}

.footer-links a {
  color: #fff;
  display: block;
  font-size: 14px;
  text-decoration: none;
}

.footer-links.hov a span {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.footer-links.hov a:hover {
  padding-left: 15px;
}
.footer-links.hov a:hover span {
  opacity: 1;
  padding-left: 10px;
  transition: all 1s ease-in-out;
}

.footer-links a:hover,
.footer a:hover {
  color: #df1f28 !important;
}

.footer-links li {
  margin-bottom: 5px;
  display: block;
  width: 100%;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 0px;
}

.footer-links li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}
