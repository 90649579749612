.section-title {
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.section-title h1 {
  font-size: 40px;
  font-weight: 700;
  padding: 0;
  text-transform: none;
  font-family: "Dancing Script", cursive;
}

.testimonial h4 {
  font-size: 18px;
  font-family: "Oswald", sans-serif;
}

.slick-prev:before,
.slick-next:before {
  color: red !important;
}

.testimonial p {
  text-align: center;
}
