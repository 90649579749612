.banner {
  position: relative;
  text-align: center;
}

.banner h2 {
  color: #f1f1f1;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0px;
  display: inline;
  margin-top: 20px;
  background: url("../images/heading-element.png") no-repeat bottom center;
  padding: 30px 40px;
  font-family: "Dancing Script", cursive;
}

.banner h2 span {
  position: relative;
  z-index: 1;
}

.page-title-link ol,
ul {
  padding-left: 0rem;
}

.page-title-link li {
  display: inline-block;
  position: relative;
  color: #3398cb;
  margin-top: 30px;
  padding-left: 0px;
}
.page-title-link li a {
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0 15px;
  font-weight: 500;
}
.page-title-link li a:hover {
  color: #3398cb;
}
.page-title-link li::after {
  background: #fff;
  bottom: 10px;
  content: "";
  height: 2px;
  margin-left: -1px;
  position: absolute;
  width: 10px;
}
.page-title-link li:last-child::after {
  content: "";
  width: 0;
  height: 0;
}

.jumbotron img {
  max-height: 300px;
}

#testimonials_bg {
  background: url("../images/testimonials_bg.jpg") fixed;
  background-size: cover;
}

#testimonials_bg p {
  color: #fff;
  text-align: center;
}

#testimonials_bg p {
  color: #fff;
}

#testimonials_bg h1 {
  color: #fff;
}

#testimonials_bg h4 {
  color: #fff;
}
