.carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

#ourmenubg {
  background: url("../images/our_menubg.jpg") fixed no-repeat;
  background-size: cover;
  background-position: center;
}

#ourmenubg {
  display: block;
}
#ourmenubg h2 {
  font-size: 36px;
  color: #333;
  font-family: "Dancing Script", cursive;
}
#ourmenubg p {
  font-size: 15px;
  text-align: center;
}

.message-box h1 {
  font-size: 42px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;

  color: #1d3249;
}

.message-box h2 {
  font-size: 36px;

  font-family: "Dancing Script", cursive;
}

.menu_border {
  background: url("../images/ourmenu_border.png");
  height: 9px;
  position: relative;
  top: 0;
}

.menu_border_bottom {
  background: url("../images/menu_border_bottom.png");
  height: 9px;
  position: relative;
  bottom: 0;
}

.home_about_section p {
  text-align: center;
}
