@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap");

@font-face {
  font-family: "BrushScriptOpti-Regular";
  src: url("./fonts/BrushScriptOpti-Regular.eot");
  src: url("./fonts/BrushScriptOpti-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/BrushScriptOpti-Regular.svg#BrushScriptOpti-Regular")
      format("svg"),
    url("./fonts/BrushScriptOpti-Regular.ttf") format("truetype"),
    url("./fonts/BrushScriptOpti-Regular.woff") format("woff"),
    url("./fonts/BrushScriptOpti-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

a,
input,
select,
button,
textarea,
input:focus,
select:focus,
button:focus,
textarea:focus,
*:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
}

:root {
  --blueColor: #005aa9;
  --carousel-button-color: #fff;
  --fancybox-color: #fff;
}

body {
  color: #333;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  line-height: 1.80857;
}

html {
  scroll-behavior: smooth;
}

p {
  text-align: justify;
}

.quote p::before {
  content: " ❝";
}

.quote p::after {
  content: " ❞ ";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

.menu_content {
  padding-left: 0px;
}
.menu_content h5 {
  font-weight: 500;
  border-bottom: 1px dashed #c0392b;
  line-height: 2;
  text-transform: capitalize;
}
.menu_content h5 span {
  font-weight: 800;
  float: right;
  font-style: italic;
  color: #c0392b;
}
.menu_content p {
  font-weight: 200;
  font-size: 16px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #083ba3 !important;
}

a.btn {
  font-family: "Oswald", sans-serif;
}
